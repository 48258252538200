import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PDFViewer } from '@react-pdf/renderer';
// @mui
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// api
import { useGetPaymentCreditors } from 'src/api/payment_settings';
import { reportOpRegistration } from 'src/api/report';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';
import { useBoolean } from 'src/hooks/use-boolean';
import { fDate } from 'src/utils/format-time';
import ReportFilter from './common/report-filter';
import OpRegistrationReportDetails from './op-registration-report-details';
import OpRegistrationReportPDF from './op-registration-report-pdf';

// ----------------------------------------------------------------------

export default function OpRegistrationReportForm({ currentUser }) {
  const flgReport = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const [reportData, setReportData] = useState([]);
  const { creditors, creditorsLoading, creditorsEmpty } = useGetPaymentCreditors();
  const [pdfUrl, setPdfUrl] = useState(null);

  const NewReportSchema = Yup.object().shape({
    date_from: Yup.mixed().nullable().required('From date is required'),
    date_to: Yup.mixed()
      .required('To date is required')
      .test(
        'date-min',
        'To Date must be later than From Date',
        (value, { parent }) => value >= parent.date_from
      ),

    payment_setting: Yup.string().required('Payment Option is required'),
    // department: Yup.string().required('Department is required'),
    visit_type: Yup.string().required('Visit Type is required'),
  });

  const defaultValues = useMemo(
    () => ({
      date_from: new Date().toLocaleDateString('en-CA', { timeZone: 'Asia/Kolkata' }).split('T')[0],
      date_to: new Date().toLocaleDateString('en-CA', { timeZone: 'Asia/Kolkata' }).split('T')[0],
      payment_setting: 'All',
      selected_departments: ['All'],
      visit_type: 'All',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewReportSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    flgReport.onTrue();
    try {
      const response = await reportOpRegistration(data);
      const pdf_url = URL.createObjectURL(response);
      setPdfUrl(pdf_url);
      flgReport.onTrue();
    } catch (error) {
      flgReport.onFalse();
      console.error(error);
      const errorMessage = error.response?.data?.reason || 'Error generating PDF. Please try again';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  });

  const onClose = () => {
    flgReport.onFalse();
    setPdfUrl(null);
  };

  if (flgReport.value) {
    if (pdfUrl) {
      return (
        <Dialog fullScreen open={flgReport.value}>
          <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
            <DialogActions
              sx={{
                p: 1.5,
              }}
            >
              <Button color="inherit" variant="contained" onClick={onClose}>
                Close
              </Button>
            </DialogActions>

            <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
              <PDFViewer src={pdfUrl} width="100%" height="100%" style={{ border: 'none' }} />
            </Box>
          </Box>
        </Dialog>
      );
    }
    return <LoadingScreen />;
  }

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Card>
        <ReportFilter isLoading={isSubmitting} />
      </Card>
    </FormProvider>
  );
}

OpRegistrationReportForm.propTypes = {
  currentUser: PropTypes.object,
};
