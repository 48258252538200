import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PDFViewer } from '@react-pdf/renderer';
// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// routes
import { log } from 'xstate';
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

// api
import { useGetPatient } from 'src/api/patient';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { usePatientWorkflow } from 'src/workflows/patient_workflow_provider';
import { fDate, fDateTime } from 'src/utils/format-time';
import { LoadingScreen } from 'src/components/loading-screen';
import { useBoolean } from 'src/hooks/use-boolean';

import { Age } from 'src/utils/calculator';
import EmrScreeningPDF from 'src/sections/print/emr-screening-pdf';
import ScreeningHeader from '../screening/screening-header';
import ScreeningHeaderDetails from '../screening/screening-header-details';
import ScreeningNav from '../screening/screening-nav';
import ScreeningInput from '../screening/screening-input';

// ----------------------------------------------------------------------

export default function ScreeningView({ record }) {
  const { patient } = record;
  const { state, send, triggerTransition } = usePatientWorkflow();
  const { doctors } = useGetPatient(patient?.patient_uuid);
  const [currentDoctors, setCurrentDoctors] = useState();
  console.log(record);
  const router = useRouter();
  const viewPrint = useBoolean();
  const settings = useSettingsContext();

  const [screeningId, setScreeningId] = useState(null);

  const isToday = (date) => {
    const givenDate = new Date(date).setUTCHours(0, 0, 0, 0);
    const updatedGivenDate = new Date(givenDate).toLocaleDateString('en-GB');
    const todayDate = new Date().toLocaleDateString('en-GB');
    return updatedGivenDate === todayDate;
  };

  useEffect(() => {
    if (
      record?.patient?.screenings[0]?.created_at &&
      isToday(record.patient.screenings[0].created_at)
    ) {
      setScreeningId(0);
    }
  }, [record, setScreeningId]);

  useEffect(() => {
    if (doctors) {
      setCurrentDoctors(doctors);
    }
  }, [doctors]);

  const details = !!patient?.screenings;

  useEffect(() => {
    send({ type: 'SCREENING_IN_PROGRESS' });
    triggerTransition();
  }, [send, triggerTransition]);

  const renderLoading = (
    <LoadingScreen
      sx={{
        borderRadius: 1.5,
        bgcolor: 'background.default',
      }}
    />
  );

  function buildPlainText(richText) {
    return richText && richText.replace(/<[^>]*>/g, '');
  }

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      flexShrink={0}
      sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72 }}
    >
      {details ? (
        <ScreeningHeader
          screeningHead={`On ${fDateTime(patient?.screenings[screeningId]?.created_at)} by ${
            patient?.screenings[screeningId]?.screened_by
          }`}
        />
      ) : (
        <ScreeningHeader
          screeningHead={`${
            patient?.medical_record_no
          } | ${patient?.patient_name?.toUpperCase()} (${patient?.gender} ${Age(
            patient?.date_of_birth
          )})`}
        />
      )}
    </Stack>
  );

  const renderNav = (
    <ScreeningNav
      screenings={patient?.screenings}
      setScreeningId={setScreeningId}
      screeningId={screeningId}
    />
  );

  const renderBody = (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      {record.patient_uuid &&
      (state.value === 'AwaitingScreening' ||
        state.value === 'ScreeningInProgress' ||
        state.value === 'ScreeningCompleted') ? (
        <ScreeningInput
          patient_uuid={record?.patient_uuid}
          screening={patient?.screenings[screeningId]}
          patient_visit_uuid={record?.patient_visit_uuid}
          department_uuid={record?.department?.department_uuid}
          doctor_uuid={record?.doctor?.doctor_uuid}
          currentDoctors={currentDoctors}
          viewPrint={viewPrint}
        />
      ) : (
        renderLoading
      )}
    </Stack>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack component={Card} direction="row" sx={{ height: '72vh' }}>
        {/* {patient?.screenings ? renderNav : ''} */}
        {renderNav}

        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
          }}
        >
          {renderHead}

          <Stack
            direction="row"
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            {renderBody}
          </Stack>
        </Stack>
      </Stack>

      <Dialog fullScreen open={viewPrint.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={viewPrint.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <EmrScreeningPDF
                medical_record_no={patient?.medical_record_no}
                patient_name={patient?.patient_name.toUpperCase()}
                gender={patient?.gender}
                age={Age(patient?.date_of_birth)}
                screening={patient?.screenings[screeningId]}
                department_name={record?.department_name}
                doctor_name={record?.doctor_name}
              />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
}
ScreeningView.propTypes = {
  record: PropTypes.any,
};
