import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import axios, { endpoints, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

export async function reportOpRegistration(post_data) {
  const response = await axios.post(endpoints.report.op_registration_report, post_data, {
    responseType: 'blob',
  });
  return response.data;
}

// ----------------------------------------------------------------------

// PDF STREAMING FOR REPORT
export async function reportOpTransaction(post_data) {
  const response = await axios.post(endpoints.report.op_transaction_report, post_data, {
    responseType: 'blob',
  });
  return response.data;
}

// ----------------------------------------------------------------------

export async function reportOpStatistics(post_data) {
  const response = await axios.post(endpoints.report.op_statistics_report, post_data);
  return response.data;
}

// ----------------------------------------------------------------------

export function useGetOpRegistration() {
  const URL = endpoints.report.op_registration_report;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      report: data?.report || [],
      reportLoading: isLoading,
      reportError: error,
      reportValidating: isValidating,
      reportEmpty: !isLoading && !data?.report.length,
    }),
    [data?.report, error, isLoading, isValidating]
  );

  return memoizedValue;
}
