import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet } from '@react-pdf/renderer';
// utils
import { fDate, fTime } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';

// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col1: { width: '10%' },
        col4: { width: '25%' },
        col6: { width: '50%' },
        col8: { width: '75%' },
        col12: { width: '100%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb15: { marginBottom: 15 },
        mb40: { marginBottom: 40 },
        pl15: { paddingLeft: 15 },
        pr15: { paddingRight: 15 },
        pt5: { paddingTop: 5 },
        pt15: { paddingTop: 15 },
        pt25: { paddingTop: 25 },
        pb5: { paddingBottom: 5 },
        pb15: { paddingBottom: 15 },
        h1: { fontSize: 24, fontWeight: 700, lineHeight: 1 },
        h3: { fontSize: 16, fontWeight: 700, lineHeight: 1 },
        h4: { fontSize: 13, fontWeight: 800, lineHeight: 1 },
        h5: { fontSize: 11, fontWeight: 700 },
        body1: { fontSize: 11 },
        body2: { fontSize: 10 },
        subtitle1: { fontSize: 12, fontWeight: 700 },
        subtitle2: { fontSize: 11, fontWeight: 700 },
        subtitle3: { fontSize: 10, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        page: {
          fontSize: 10,
          lineHeight: 1.6,
          fontFamily: 'Roboto',
          // border: '1px solid #000',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 0px 24px',
        },
        footer: {
          /* left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          margin: 'auto', */
          padding: '10px 24px 24px 24px',
          borderTopWidth: 1,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        horizontalLine: {
          padding: '0',
          borderTopWidth: 1,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          padding: '8px 0',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        noPadding: {
          paddingBottom: 0,
        },
        boxPadding: {
          padding: 15,
        },
        tableCell_1: {
          width: '5%',
        },
        tableCell_2: {
          width: '50%',
          paddingRight: 16,
        },
        tableCell_3: {
          width: '15%',
        },
        textCenter: {
          textAlign: 'center',
        },
        alignCenter: {
          alignItems: 'center',
        },
        boxBorder: {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        bottomBorder: {
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        leftBorder: {
          borderLeftWidth: 1,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        bgDark: {
          backgroundColor: '#000000',
          color: '#FFFFFF',
        },
        h80: {
          height: 80,
        },
        h100: {
          minHeight: 100,
        },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function EmrScreeningPDF({
  medical_record_no,
  patient_name,
  gender,
  age,
  screening,
  department_name,
  doctor_name,
}) {
  const styles = useStyles();

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={styles.boxBorder}>
          <View style={[styles.gridContainer, styles.bottomBorder]}>
            <View style={[styles.col1, styles.pt25, styles.alignCenter]}>
              <Image source="/logo/sn_logo.png" style={{ width: 50, height: 50 }} />
            </View>

            <View
              style={[{ width: '40%', alignItems: 'center', flexDirection: 'column' }, styles.pt25]}
            >
              <Text style={[styles.h3, styles.textCenter]}>SREE NARAYANA INSTITUTE</Text>
              <Text style={[styles.h3, styles.textCenter]}>OF AYURVEDIC</Text>
              <Text style={[styles.h3, styles.textCenter, styles.mb8]}>STUDIES AND RESEARCH</Text>
              <Text style={[styles.subtitle2, styles.textCenter]}>
                R. Sankar Nagar, Karimpinpuzha P.O.,
              </Text>
              <Text style={[styles.subtitle2, styles.pb15]}>Pangod, Puthoor</Text>
            </View>

            <View style={[styles.col6, styles.leftBorder, styles.boxPadding, styles.pt25]}>
              <Text style={[styles.subtitle1]}>Patient Name: {patient_name}</Text>
              <Text style={[styles.subtitle1]}>MR No.: {medical_record_no}</Text>
              <Text style={[styles.subtitle1]}>
                Age/Gender: {age} ({gender})
              </Text>
              <Text style={[styles.subtitle1]}>Date: {fDate(screening?.created_at)}</Text>
            </View>
          </View>

          <View
            style={[styles.col12, styles.alignCenter, styles.bgDark, styles.noPadding, styles.mb4]}
          >
            <Text style={[styles.h1, styles.pb5]}>O.P CASE RECORD</Text>
          </View>
          <View style={[styles.col12, styles.alignCenter, styles.mb15]}>
            <View style={[styles.alignCenter, styles.bgDark, styles.noPadding, { width: '90%' }]}>
              <Text style={[styles.h4, styles.pb5, styles.pt5]}>INITIAL ASSESSMENT</Text>
            </View>
          </View>

          <View
            style={[
              styles.gridContainer,
              styles.mb4,
              { flexDirection: 'column', paddingLeft: 20, paddingRight: 20 },
            ]}
          >
            <View style={[styles.mb15, styles.h100]}>
              <Text style={[styles.h4, styles.mb8, { textDecoration: 'underline' }]}>
                I. Chief Complaints (with duration):
              </Text>
              <Text style={[styles.subtitle3, styles.pl15]}>{screening?.chief_complaints}</Text>
            </View>
            <View style={[styles.mb15, styles.h100]}>
              <Text style={[styles.h4, styles.mb8, { textDecoration: 'underline' }]}>
                II. History of Past Illness:
              </Text>
              <Text style={[styles.subtitle3, styles.pl15]}>{screening?.illness_history}</Text>
            </View>
            <View style={[styles.mb15, styles.h100]}>
              <Text style={[styles.h4, styles.mb8, { textDecoration: 'underline' }]}>
                III. Personal History:
              </Text>
              <View style={[styles.gridContainer]}>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Appetite: {screening?.appetite}
                    {screening?.appetite && screening?.appetite_remarks ? ', ' : ''}
                    {screening?.appetite_remarks}
                  </Text>
                </View>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Diet: {screening?.diet}
                    {screening?.diet && screening?.diet_remarks ? ', ' : ''}
                    {screening?.diet_remarks}
                  </Text>
                </View>
              </View>
              <View style={[styles.gridContainer]}>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Bowel: {screening?.bowel}
                    {screening?.bowel && screening?.bowel_remarks ? ', ' : ''}
                    {screening?.bowel_remarks}
                  </Text>
                </View>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Addiction: {screening?.addiction}
                    {screening?.addiction && screening?.addiction_remarks ? ', ' : ''}
                    {screening?.addiction_remarks}
                  </Text>
                </View>
              </View>
              <View style={[styles.gridContainer]}>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Bladder: {screening?.bladder}
                    {screening?.bladder && screening?.bladder_remarks ? ', ' : ''}
                    {screening?.bladder_remarks}
                  </Text>
                </View>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Allergy: {screening?.allergy}
                    {screening?.allergy && screening?.allergy_remarks ? ', ' : ''}
                    {screening?.allergy_remarks}
                  </Text>
                </View>
              </View>
              <View style={[styles.gridContainer]}>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Sleep: {screening?.sleep}
                    {screening?.sleep && screening?.sleep_remarks ? ', ' : ''}
                    {screening?.sleep_remarks}
                  </Text>
                </View>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Exercise: {screening?.exercise}
                    {screening?.exercise && screening?.exercise_remarks ? ', ' : ''}
                    {screening?.exercise_remarks}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.mb15, styles.h80]}>
              <Text style={[styles.h4, styles.mb8, { textDecoration: 'underline' }]}>
                IV. Body Mass Index (BMI):
              </Text>
              <View style={[styles.gridContainer]}>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Height(cm): {screening?.height}
                  </Text>
                </View>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    Weight(kg): {screening?.weight}
                  </Text>
                </View>
              </View>
              <View style={[styles.gridContainer]}>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>
                    BMI(kg/m&#178;): {screening?.bmi}
                  </Text>
                </View>
                <View style={[styles.col6]}>
                  <Text style={[styles.subtitle3, styles.pl15]}>{screening?.bmi_remark}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.horizontalLine]} />
          <View style={[styles.col12, styles.pt25, styles.h100]}>
            <Text style={[styles.subtitle3, styles.pl15]}>
              Referred to: {department_name} | Dr. {doctor_name}{' '}
            </Text>
          </View>
          <Text style={[styles.subtitle3, styles.pr15, styles.alignRight]}>
            Screened by: {screening?.screened_by}
          </Text>
        </View>
      </Page>
    </Document>
  );
}

EmrScreeningPDF.propTypes = {
  medical_record_no: PropTypes.string,
  patient_name: PropTypes.string,
  gender: PropTypes.string,
  age: PropTypes.string,
  screening: PropTypes.object,
  department_name: PropTypes.string,
  doctor_name: PropTypes.string,
};
