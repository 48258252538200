import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  // UPDATED ENDPOINTS
  workflow: {
    transition: '/api/workflow/transition',
    current_state: '/api/workflow/current_state',
  },
  patient: {
    create: '/api/patient/patient_create',
    quick_update: '/api/patient/patient_quick_update',
    visit: '/api/patient/patient_visit',
    get_visit: '/api/patient/get_patient_visit',
    list: '/api/patient/patient_list',
    details: '/api/patient/patient_details',
    years: '/api/patient/get_years',
    search: '/api/patient/patient_search',
    uuid: '/api/patient/patient_uuid',
    projection_patient_uuid: '/api/patient/department_patient_uuid',
    projection_new_patient: '/api/patient/department_new_patient',
  },
  emr: {
    state_based_patients: '/api/emr/state_based_patients',
    record: '/api/emr/record',
    create: '/api/emr/screening_create',
  },
  departments: {
    create: '/api/department/department_create',
    doctors: '/api/doctor/department_doctors',
    list: '/api/department/department_list',
    details: '/api/department/department_details',
    search: '/api/department/department_search',
    patients_count: '/api/department/department_patient_count',
  },
  doctors: {
    create: '/api/doctor/doctor_create',
    quick_update: '/api/doctor/doctor_quick_update',
    delete: '/api/doctor/doctor_delete',
    list: '/api/doctor/doctors_list',
    active: '/api/doctor/active_doctors',
    details: '/api/doctor/doctor_details',
    search: '/api/doctor/doctor_search',
  },
  report: {
    op_registration_report: '/api/report/op_registration_report',
    op_transaction_report: '/api/report/op_transaction_report',
    op_statistics_report: '/api/report/op_statistics_report',
  },
  master: {
    payment_settings: {
      create: '/api/master/payment_setting_create',
      list: '/api/master/payment_setting_list',
      creditors: '/api/master/payment_creditor_list',
      // search: '/api/master/search',
    },
  },
};
